
.meio{
    width: 100vw;
    height: 100vh;
    grid-area:tela05;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../imagens/NOVOS-ELEMENTOS/FUNDO\ RUÍDO.png");
    background-size: 100% 100%;
}
.centerMeio{
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.fotoPrincipal{
    background-image: url("../../../imagens/02/DuoMi.png");
    background-size: cover;
    width: 300px;
    height: 320px;
    justify-content: center;
    display: flex;
    transform: translate(0,-80px);
}
.navBarra{
    width: 100vw;
    display: flex;
    justify-content: center;
}