.cantoSuperiorEsquerdo{
    width:100vw;
    height: 100vh;
    grid-area: tela01;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Estatico{
    width: min-content;
    height:min-content;
    display: grid;
    padding-top: 500px;
    
}
.grid{
   grid-template-areas: 
   "emily emily emilyn emilyn emilyn ."
   "emily emily texto texto texto TamisNome"
   "fotodupla fotodupla tamis tamis tamis TamisNome" 
   "fotodupla fotodupla tamis tamis tamis TamisNome";
   grid-template-columns: 150px 200px 150px 150px 150px 150px;
   grid-template-rows: 150px 150px 150px 150px 150px 150px;
}

.orkut{
    width: 1200px;
    height: 100px;
    position:relative;
    top:500%;
    left:-100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.cantoMeioEsquerdoDiv{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cantoMeioEsquerdoDivDiv{
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divStart{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../imagens/NOVOS-ELEMENTOS/ORKUT-STAR.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .divHeart{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../imagens/NOVOS-ELEMENTOS/ORKUT-CORAÇÃO.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .divSmile{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../imagens/NOVOS-ELEMENTOS/ORKUT-SMILE.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .divCubs{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../../imagens/NOVOS-ELEMENTOS/ORKUT-CUBO\ AZUL.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }



