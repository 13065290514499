.cantoInferiorEsquerdo{
    width: 100vw;
    height: 100vh;
    grid-area:tela07;
    display: flex;
    align-items: center;
    justify-content: center;
}
.centerCantoInferior{
    width: min-content;
    height: min-content;
    margin:auto;
    display: flex;
}
.criatividade{
    width: 1150px;
    height: 567px;
    border-radius:50px; 
    border:10px solid transparent; 
    background:linear-gradient(90deg,var(--terciaria),var(--secundaria),var(--primaria)) border-box; 
    align-items: center;
    justify-content: center;
    display: flex;
    z-index:0;

}

.drawCanvas{
    border-radius:50px; 
}

.selecionarCores{
    width: 100px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left:10px;
}
.desenhar{
    width:50px;
    border:none;
    background:transparent;
    cursor:pointer;
    margin-bottom: 10px;
    fill: url(#MyGradient)
}
.palheta {
    width:var(--dimensao);
    height:var(--dimensao);
    background: none;
    border: none;
}
.colorpick-eyedropper-input-trigger{
    display: none;
}
.clear{
    width:var(--dimensao);
    height:var(--dimensao);
    cursor:pointer;
    margin-bottom: 10px;
    border:none;
    fill: url(#MyGradient)
}
.arrowLeft{
    width:var(--dimensao);
    height:var(--dimensao);
    cursor:pointer;
    margin-bottom: 10px;
    border: none;
    fill: url(#MyGradient);
    display:flex;
    justify-content: center;
    align-items: center;
}
.penSizeRef{
    width:10px;
    height:10px;
    border-radius:50%;
    background-color: black;
}
.containerInput{
    width: 2px;
    height: 90px;
    background-color: black;
    margin-left: 23px;
    
}
.inputPen{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: black;
    margin-left: -5px;
    cursor:pointer;
}
.erase{
    width:var(--dimensao);
    height:var(--dimensao);
    background:transparent;
    border: none;
    shape-rendering: crispEdges;
    fill: url(#MyGradient);
    cursor:pointer;
    margin-bottom: 10px;
}
.salvarDesenho{
    width: 42px;
    height: 51px;
    background-image: url("../../../imagens/06/ARQUIVO-ENVIE.png");
    background-size: cover; 
    border:none; 
    cursor:pointer;  
}