.cantoSuperiorDireito{
    width:100vw;
    height:100vh;
    grid-area:tela03;
    display: flex;
    align-items: center;
    justify-content:center;
}
.PcSemColapsar{
    margin: auto;
    width:min-content;
    height: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fotoDeFundo{
    background-image: url("../../../imagens/NOVOS-ELEMENTOS/03-ATENDIMENTO/PC-COMPLETO.png");
    background-size: cover;
    width:771px;
    height: 669px;
    margin-right: 100px;
}
.linkFor{
    background-image:url(../../../imagens/05/CURSOR-QUEM\ SOMOS.png);
    background-size: cover;
    width: 84px;
    height: 95px;
    transform: translate(306px, 179px) rotate(16deg);
    border: none;
    text-decoration:none;
    border-block-style: none;
}
.cantoSuperiorDireito h1 {
    color: var(--primaria);
    width: 260px;
    font-size: 1.3em;
    text-transform: uppercase;
    
}
.cantoSuperiorDireito span{
    color: var(--secundaria);
}
