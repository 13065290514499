.celBackground{
    display:none;
}

@media (max-width: 800px) {
    .back-ground{
        display: none
        
    }
    .quadrado{
        display: none;
    }
    .celBackground{
        display:flex;
        flex-direction: column;
        width: 100vw;
        height: max-content;
    }
    .container-meio{
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
    }
    .fotoPrincipal{
        width: 247px;
        height: 263px;
    }
    .navbar{
        padding-top: 50px;
    }
    .container-quemsomos{
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        border: 2px solid red;
    }
    .containerNomeFoto{
        width:100%;
        height:min-content;
        border:2px solid green;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .containerFotoEmily{
        width:50%;
        height: max-content;
        border: 2px solid pink;
    }
    .containerNomeEmily{
        width:50%;
        height: 106px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }

}