.containerDupla{
    grid-area: fotodupla;
    width:300px;
    height:243px;
    transform:rotate(-6deg);
    align-self: flex-start;
    margin-left:50px;
    transform-style: preserve-3d;
    transition-duration: 2s;
}
.fundoDupla{
    width:314px;
    height:243px;
    background-image: url('../../../../imagens/NOVOS-ELEMENTOS/01-QUEM-SOMOS/FRAME\ 3\ -DUOMI\ -\ FUNDO.png');
    background-size: cover;
    position:absolute;
    z-index: 0;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    border: 10px solid;
    border-image: linear-gradient(90deg,var(--primaria),var(--secundaria),var(--terciaria));
    border-image-slice: 1;
    cursor: pointer;
    
}
.fotoDupla{
    position:absolute;
    background-image: url("../../../../imagens/05/DUOMI.png");
    background-size: cover;
    width:331px;
    height:243px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 1;
    cursor: pointer;
    backface-visibility: hidden;
}
.fotoDuplaCursor{
    background-size: cover;
    background-image: url(../../../../imagens/05/CURSOR-QUEM\ SOMOS.png);
    width:50px;
    height: 59px;
    margin-right: 17px;
    transform: translate(-20px, 30px) rotate(6deg);
}

@media (max-width: 800px) {
    .containerDupla{
        width:50%;
        height:90px;
        display:flex;
        align-items:center;
        justify-content:center;
        border: solid black;
        margin-left:0;
        transform:rotate(0deg);
    }
    .fotoDupla{
        width: 114px;
        height: 82px;
        transform: rotate(359deg);
    }
    .fotoDuplaCursor{
        width: 25px;
        height: 29px;
        transform: translate(-4px, 19px) rotate(6deg)
    }

}