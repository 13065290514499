:root{
    --dimensao: 50px;
    --primaria: #4D0C5C;
    --secundaria: #B01446;
    --terciaria:#FFB113;
}
*{
    margin: 0;
    padding: 0;
    font-family:"raleway";
    font-weight:1000;
    
}
body {
    cursor:grab;
    overflow: hidden;

} 
.screenload{
    animation: blink 3s infinite  alternate;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: rgb(243, 241, 241);
    position:fixed;
    z-index: 1001;
}

.c-loader {
    animation: is-rotating .8s infinite;
    border: 6px solid var(--secundaria);
    border-radius: 50%;
    border-top-color: var(--primaria);
    border-left-color: var(--secundaria);
    border-bottom-color: var(--terciaria);
    height: 50px;
    width: 50px;
  }
  @keyframes blink {
      to {
        background-color: rgb(223, 215, 215)
      }
}
  
  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }
.quadrado{
    position: fixed;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: linear-gradient(45deg,#4D0C5C,#B71B43,#FAAC10);
    z-index:1000;
    top: 80vh;
    left: 90vw;
    cursor: pointer;
    display: flex;
    text-align: center;
    justify-items: center;
    align-items: center;
}
.quadrado h1 {
  font-size:1em;
  color:white;
  padding-left: 17px;
}
.quadradoNone {
    display:none;
}
.back-ground{
    width: min-content;
    height: min-content;
    
   
}
 
.back-ground{
    background-image: url("../../imagens/NOVOS-ELEMENTOS/BRANCO-CINZA.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: grid;
    grid-template-areas: 
    "tela01 tela02 tela03"
    "tela04 tela05 tela06"
    "tela07 tela08 tela09";
    /* create grid symmetrical*/
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
   /*  grid-gap: 200px; */

}
.meioInferior{
  grid-area: tela08;
  background-image: url("../../imagens/07/SMILE.png");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center;
}
.cantoMeioEsquerdo{
  grid-area: tela04;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center;
  
}







