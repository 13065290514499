.flip{
  display:none;
}
.emilyContainer{
    display: flex;
    justify-content: center;
    margin-left: 200px;
    transform-style: preserve-3d;
    transition-duration: 2s;
    transform: rotate(-6deg);
 }
.Emily{
    width: 277px;
    height: 267px;
    background-image: url("../../../../imagens/05/FRAME_EMILY_FRENTE.png");
    background-size: cover;
    grid-area: emily;
    position:absolute;
    cursor:pointer;
    z-index: 6;
    backface-visibility: hidden;
    
}
.fundoEmily{
    width: 255px;
    height: 262px;
    background-image: url("../../../../imagens/NOVOS-ELEMENTOS/01-QUEM-SOMOS/FRAME\ 1\ -EMILY\ -\ FUNDO.png");
    background-size:cover;
    position:absolute;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    border: 10px solid;
    border-image: linear-gradient(90deg,var(--primaria),var(--secundaria),var(--terciaria));
    border-image-slice: 1;
    cursor: pointer;
  }

.balao{
    width:72px;
    height:51px;
    background-image: url("../../../../imagens/NOVOS-ELEMENTOS/01-QUEM-SOMOS/RABISCO\ -\ BALÃO.png");
    background-size: cover;
    transform: translate(-20px, 53px) rotate(15deg);

}
.seta_curva{
    width:120px;
    height: 50px;
    background-image: url("../../../../imagens/NOVOS-ELEMENTOS/01-QUEM-SOMOS/RABISCO\ -\ SETA\ -\ EMILY.png");
    background-size: cover;
    transform: translate(240px,0px);
    
} 
 