@media (max-width: 800px) {
    .back-ground {
        display: grid;
        grid-template-areas: 
        "tela05"
        "tela01" 
        "tela09"
        "tela04"
        "tela02"
        "tela06"
        "tela07"
        "tela08"
        "tela03";
        /* create grid symmetrical*/
         background-color: rgb(243, 241, 241);
        
  }
  .quadrado{
      display: none;
  }
}