.navbar {
   
   height: 80px;
   display: flex;
   justify-content: center; 
   align-items: center;
   font-size: 1.2rem;
   position: relative;
   text-align: center;
   width: 60%;
   font-weight: bold;
}
 
 
 .navbar-container {
   animation: infinite 2s infinite;
   height: 80px;
   max-width: 1500px;
   background:linear-gradient(45deg,#4D0C5C,#B71B43,#FAAC10);
   display: grid;
   grid-template-columns: repeat(3, auto);
   list-style: none;
   text-align: center;
   width: 60vw;
   justify-content: center;
   border-radius: 50px;
 }
 
 .nav-menu {
   display: grid;
   grid-template-columns: repeat(3, auto);
   grid-gap: 20px;
   list-style: none;
   text-align: center;
   width: 60vw;
   justify-content: space-between;
   border-radius: 60px;
   font-family:"raleway";
   text-transform:uppercase ;
   animation: infinite 1s ease-in-out;
  
 }
 
 
 .nav-links {
   color: #fff;
   display: flex;
   align-items: center;
   text-decoration: none;
   height: 100%;
}
.nav-menu > li:nth-child(1) {

  padding-left: 8vw;
 
}
.nav-menu > li:nth-child(2) {

  padding: 0 50px ;
  border-right: 1px solid #fff;
  border-left : 1px solid #fff;
}
.nav-menu > li:nth-child(3) {

  padding-right: 8vw;
}

 


 

 
 