.containerTamis {
  width: 300px;
  height: 296px;
  grid-area: tamis;
  position: relative;
  transform-style: preserve-3d;
  transition-duration: 2s;
  position: relative;
  display: flex;
  transform: rotate(-2deg);
  margin-left: 40px;
}
.tamis {
  background-image: url("../../../../imagens/05//FRAME-TAMIS.png");
  background-size: cover;
  width: 300px;
  height: 296px;
  grid-area: tamis;
  z-index: 1;
  transition-duration: 2s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  cursor: pointer;
}
.tamisDescricao {
  width: 290px;
  height: 290px;
  position: absolute;
  background-image: url("../../../../imagens/NOVOS-ELEMENTOS/01-QUEM-SOMOS/FRAME\ 2\ -TAMIS\ -\ FUNDO.png");
  background-size:cover;
  transition-duration: 2s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  z-index: 0;
  border: 10px solid;
  border-image: linear-gradient(90deg,var(--primaria),var(--secundaria),var(--terciaria));
  border-image-slice: 1;
  cursor: pointer;
}

.tamiSeta {
  background-image: url("../../../../imagens/NOVOS-ELEMENTOS/01-QUEM-SOMOS/RABISCO\ -\ SETA\ -\ TAMIS.png");
  background-size: cover;
  width: 150px;
  height: 44px;
  transform: translate(220px, 60px) rotate(05deg);
}
.tamisNome {
  width: 60px;
  height:350px;
  display: flex;
  flex-direction: column;
  grid-area: TamisNome;
  margin-top: 50px;
  transform: translate(-50px,50px);
  background-image: url("../../../../imagens/NOVOS-ELEMENTOS/01-QUEM-SOMOS/NOME\ TAMIS.png");
  background-size:cover;
}
